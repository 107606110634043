$(document).ready(function() {

    //
    // Akkordeon
    //
    function toggleAllAccordeonElements(){
        $( ".accordeonSwitchButton" ).each(function() {

            $(this).on("click", function(event) {
                // 32=Leertaste, 13=Return, 1=Linke Maustaste
                if (event.which == 32 || event.which == 13 || event.which == 1) {
                    event.preventDefault();

                    var Button = $(this);
                    var GruppenName = Button.parent().parent().parent().attr('id');
                    var Gruppe = $('div#' + GruppenName);
                    var GruppenHeaderButtons = $('div#' + GruppenName + ' button.bund-accordion-header');
                    var GruppenHeaderButtonsIcons = $('div#' + GruppenName + ' button.bund-accordion-header > span.fas');
                    var GruppenContent = $('div#' + GruppenName + ' > div.bund-accordion-content');
                    var ButtonAriaExpanded = Button.attr('aria-expanded');
                    var ButtonIcon = Button.find('span.fas');
                    //console.log(GruppenName);

                    // Alles öffnen
                    if(ButtonAriaExpanded == 'false'){
                        Button.attr('aria-expanded','true');
                        ButtonIcon.removeClass('fa-angle-down');
                        ButtonIcon.addClass('fa-angle-up');
                        Button.find('span.accordeonSwitch-open').addClass('hidden');
                        Button.find('span.accordeonSwitch-close').removeClass('hidden');

                        GruppenHeaderButtons.attr('aria-expanded','true');
                        GruppenHeaderButtons.removeClass('bund-accordion-header-button');
                        GruppenHeaderButtons.addClass('bund-accordion-header-button-active');
                        GruppenHeaderButtonsIcons.removeClass('fa-plus');
                        GruppenHeaderButtonsIcons.addClass('fa-minus');

                        GruppenContent.removeClass('hidden');
                    }

                    // Alles schließen
                    if(ButtonAriaExpanded == 'true'){
                        Button.attr('aria-expanded','false');
                        ButtonIcon.removeClass('fa-angle-up');
                        ButtonIcon.addClass('fa-angle-down');
                        Button.find('span.accordeonSwitch-open').removeClass('hidden');
                        Button.find('span.accordeonSwitch-close').addClass('hidden');

                        GruppenHeaderButtons.attr('aria-expanded','false');
                        GruppenHeaderButtons.removeClass('bund-accordion-header-button-active');
                        GruppenHeaderButtons.addClass('bund-accordion-header-button');
                        GruppenHeaderButtonsIcons.removeClass('fa-minus');
                        GruppenHeaderButtonsIcons.addClass('fa-plus');


                        GruppenContent.addClass('hidden');
                    }

                }
            });
        });
    }
    toggleAllAccordeonElements();

    function toggleSingleAccordeonElement() {
        $( "button.bund-accordion-header" ).each(function() {

            $(this).on("click", function(event) {
                // 32=Leertaste, 13=Return, 1=Linke Maustaste
                if (event.which == 32 || event.which == 13 || event.which == 1) {
                    event.preventDefault();

                    var Button = $(this);
                    var ButtonAriaExpanded = Button.attr('aria-expanded');
                    var ButtonControls = Button.attr('aria-controls');
                    var Content = $('#' + ButtonControls);
                    var ButtonIcon = Button.find('span.fas');
                    var GruppenName = Button.parent().parent().attr('id');
                    var Gruppe = $('div#' + GruppenName);

                    // dieses Element öffnen
                    if(ButtonAriaExpanded == 'false'){
                        // alle dieser Gruppe schließen
                        Gruppe.find('button.bund-accordion-header').attr('aria-expanded','false');
                        Gruppe.find('button.bund-accordion-header > span.fas').removeClass('fa-minus');
                        Gruppe.find('button.bund-accordion-header > span.fas').addClass('fa-plus');
                        Gruppe.find('button.bund-accordion-header').removeClass('bund-accordion-header-button-active');
                        Gruppe.find('button.bund-accordion-header').addClass('bund-accordion-header-button');
                        Gruppe.find('div.bund-accordion-content').addClass('hidden');

                        Button.attr('aria-expanded','true');
                        Button.removeClass('bund-accordion-header-button');
                        Button.addClass('bund-accordion-header-button-active');
                        ButtonIcon.removeClass('fa-plus');
                        ButtonIcon.addClass('fa-minus');

                        Content.removeClass('hidden');
                    }

                    // dieses Element schließen
                    if(ButtonAriaExpanded == 'true'){
                        Button.attr('aria-expanded','false');
                        Button.removeClass('bund-accordion-header-button-active');
                        Button.addClass('bund-accordion-header-button');
                        ButtonIcon.removeClass('fa-minus');
                        ButtonIcon.addClass('fa-plus');

                        Content.addClass('hidden');
                    }
                }
            });
        });
    }
    toggleSingleAccordeonElement();

});